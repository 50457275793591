import React, { useState, useRef } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function AnotherOtp() {

  const navigate = useNavigate();
  const selectedBank = localStorage.getItem('selectedBank');



  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
const [otp, setOtp] = useState([""]);
const inputRef = useRef(null);

  const handleBack = () => {
    navigate('/pages/another-credentials');
  };

const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 5) {
      setOtp(value);
    }
  };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     navigate("/pages/connect-successful");
//   }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  const hardcodedOtp = "00000";

  if (otp === hardcodedOtp) {
      navigate('/pages/connect-successful');
  } else {
    setIsLoading(false);
      toast.error("Input the correct OTP");
      setOtp("");
  }
};

  return (
    <div className="connect-account-overlay">
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Authorise Direct Debit</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '17px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
          <div className='img-logo'>
              {selectedBank === 'gtb' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/gtbank.svg'}
                  alt="GTBank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'zenith' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/zenith-bank.svg'}
                  alt="Zenith Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'kuda' && (
                <img
                  src={process.env.PUBLIC_URL + 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/kuda-bank-icon.png'}
                  alt="Kuda Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
            </div>

           <div className='enter-token'>
            <p className='token-nibss'>A token of NGN 50.00 will be debited by NIBSS from your account ****5809 </p>

            <div className="rave-verify-otp" style={{paddingBottom:"0"}}>
              <div className="rave-verify-field">
              <input
                  type="password"
                  name="otp"
                  maxLength="5"
                  className="rave-otp"
                  value={otp}
                  onChange={handleInputChange}
                  ref={inputRef}
                  style={{ letterSpacing: '20px' }} 
                  />
              </div>
            </div>

            <p className='otp-to'>Please input OTP to complete this transaction</p>
          </div>

            <button className="btn" onClick={handleSubmit} disabled={isLoading} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Confirm
                  </>
                )}
              </button>
          </div>
        </div>
      </div>
      <div className='foot-note'>You are currently in Sandbox mode. Credentials » otp: 00000</div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default AnotherOtp;
