import React from 'react';
import { Link } from 'react-router-dom';

import './Header.scss'



const Navbar = () => {
   
    return (
        <nav className="mainmenu-nav">
            <div className="mainmenu">    
                <ul className='main_menu_nav'>
                    <li>
                        <Link to="/link"> Link</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/checkout-landing">Checkout</Link>
                    </li> 
                    <li>
                        <Link to="/pages/verify-identity">KYC Onboarding</Link>
                    </li>
                </ul>  
                
                <div className='menu-item-btns'>       
                        <a href="https://merchant.getflick.co/" className='nav-signIn'>Sign In</a>
                        <div className='sign-in-line'></div>     
                        <a href="https://merchant.getflick.co/signUp"   className='btn-landing'>Get started</a>
                </div>
                
            </div>
            
        </nav>
        
    )
}

export default Navbar;