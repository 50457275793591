import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function AssociatedChooseYourBank() {

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const selectedBank = localStorage.getItem('selectedBank');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClick = () => {
    if (!selectedOption) {
      toast.error('Please select a connection method');
      return;
    }

    setIsLoading(true);
    navigate('/pages/associated-enter-credentials');
    
  };

  const handleBack = () => {
    navigate('/pages/associated-account');
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div
            className="flick-logo"
            style={{ position: 'relative', marginTop: '20px' }}
          >
            <img
              src={process.env.PUBLIC_URL + '/images/round-flick-logo.svg'}
              alt="logo"
              style={{ width: '48px', height: '48px' }}
            />
            {selectedBank === 'gtb' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/gtbank.svg'}
                  alt="GTBank"
                  style={{
                position: 'absolute',
                top: '0',
                right: '115px',
                width: '50px',
                height: '50px',
              }}
                />
              )}
              {selectedBank === 'zenith' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/zenith-bank.svg'}
                  alt="Zenith Bank"
                  style={{
                position: 'absolute',
                top: '0',
                right: '115px',
                width: '50px',
                height: '50px',
              }}
                />
              )}
              {selectedBank === 'kuda' && (
                <img
                  src={process.env.PUBLIC_URL + 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/kuda-bank-icon.png'}
                  alt="Kuda Bank"
                  style={{
                position: 'absolute',
                top: '0',
                right: '115px',
                width: '50px',
                height: '50px',
              }}
                />
              )}
          </div>
          <p className="selection">Select connection method</p>
          <div className="connect-effortless">
            <div className="border-line">
              <div
                className="connect-title"
                onClick={() => setSelectedOption('internet_banking')}
              >
               <div className='internet-mobile'>
                  <img
                    src={process.env.PUBLIC_URL + '/images/connect.svg'}
                    alt="logo"
                  />
                  <div className="connect-your-account-link">
                    <p className="connect-your-account">Internet banking</p>
                    <p className="securely-connect" style={{ marginBottom: '18px' }}>
                      Use your web username & password
                    </p>
                  </div>
                </div>
                <input
                  type="radio"
                  name="options"
                  value="internet_banking"
                  checked={selectedOption === 'internet_banking'}
                  onChange={handleOptionChange}
                  className="custom-radio"
                />
              </div>
            </div>
            <div
              className="connect-title"
              onClick={() => setSelectedOption('mobile_banking')}
            >
              <div className='internet-mobile'>
                <img
                  src={process.env.PUBLIC_URL + '/images/your-data.svg'}
                  alt="logo"
                />
                <div className="connect-your-account-link">
                  <p className="connect-your-account">Mobile banking</p>
                  <p className="securely-connect" style={{marginRight:"0px"}}>
                    Use your username or account <br/> number & password
                  </p>
                </div>
              </div>
              <input
                type="radio"
                name="options"
                value="mobile_banking"
                checked={selectedOption === 'mobile_banking'}
                onChange={handleOptionChange}
                className="custom-radio"
              />
            </div>
            <button
              className="btn"
              style={{ background: '#2EBDB6' }}
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img
                    src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'}
                    alt="arrow btn"
                    style={{ display: 'inline', marginLeft: '5px' }}
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default AssociatedChooseYourBank;
