import React, { useState } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer} from "react-toastify";

function SelfieCheck() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true)
    navigate("/pages/align-head")
  };
 
  const handleBack = () => {
    navigate("/pages/verification");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{paddingTop:"152px"}}>
        <div className="connect-account-box">
          <div className="choose-bank" style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
         
          <div style={{ padding: "9px 21px 21px 21px" }}>
            <div className="face-id">
                <img src={process.env.PUBLIC_URL + "/images/face-id.svg"} alt="close"/>
                <p className="selfie">Selfie check</p>
                <p className="selfie-check">A selfie check helps us make sure you are a real person</p>
                <img src={process.env.PUBLIC_URL + "/images/cam-permission.svg"} alt="close"/>
            </div>
           
            <button className="btn" style={{ fontSize:"14px", background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              
                  ) : (
                        <>
                        Allow camera access                
                        </>
                    )}
            </button>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default SelfieCheck;
