import React, { useRef, useState } from 'react'
import BankTransfer from '../../components/checkout/bankTransfer/BankTransfer';
import '../../components/checkout/card/card.scss';
import '../../components/checkout/landing/landing.scss'
import { useNavigate,useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Layout from '../checkout/layout/Layout';
import { ToastContainer, toast } from 'react-toastify';

function CardDetails() {


  // useEffect(()=>{
  //   if(!localStorage.getItem('auth')) return navigate('/')
  // // eslint-disable-next-line
  // },[])
  // eslint-disable-next-line
  const auth = JSON.parse(localStorage.getItem('auth'))
  const dashData = JSON.parse(localStorage.getItem('dashResult'))

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
  const [otp, setOtp] = useState(['', '', '', '']); // Initialize an array with empty strings for each digit
  const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null)]; // Create refs for each input field
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  
  const  {cardValues} =  location?.state || {}
  // eslint-disable-next-line
  const accessCode = cardValues?.accessCode || dashData?.accessCode
  // eslint-disable-next-line
  const openModal = () => {
      setIsModalOpen(!isModalOpen);
  };

  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // eslint-disable-next-line
  const handleBankSelection = (bankName) => {
    setSelectedBank(bankName);
    closeModal();
  };
  const renderContent = ()=>{
    switch(selectedBank.name){
        case "Card":
            return "Card";
        
        case "Transfer":
            return <BankTransfer />;
       
        // case "Pay by Bank":
        //     return <Bank />;
       
        default:
            return "Card"
    }
   
  }

  // const handleInputChange = (e, index) => {
  //   let value = e.target.value;

  //   if (value.length > 1) {
  //       value = value.charAt(0);
  //   }

  //   if (/^[0-9]*$/.test(value) && index >= 0 && index < otpInputs.length) {
  //     otp[index] = value;
  //     setOtp([...otp]);

  //     // Auto focus on the next input field if available
  //     if (index < otpInputs.length - 1 && value !== '') {
  //       otpInputs[index + 1].current.focus();
  //     }
  //   }
  // };
  // const handleInputChange = (e, index) => {
  //   const value = e.target.value;
  
  //   if (value === '' || /^[0-9]*$/.test(value)) {
  //     const newOtp = [...otp];
  
  //     if (value === '' && index > 0) {
  //       // Handle backspace: clear the current input and move focus to the previous input
  //       newOtp[index] = '';
  //       setOtp(newOtp); // Update the state to trigger re-render
  //       otpInputs[index - 1].current.focus();
  //     } else {
  //       // Update the current input and move focus to the next input
  //       newOtp[index] = value.charAt(0);
  //       if (index < otpInputs.length - 1 && value !== '') {
  //         otpInputs[index + 1].current.focus();
  //       }
  //     }
  
  //     setOtp(newOtp);
  //   }
  // };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
  
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value.charAt(0);
      setOtp(newOtp);
  
      if (index < otpInputs.length - 1 && value !== '') {
        otpInputs[index + 1].current.focus();
      }
    }
  };
  
  const handleInputKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      // Handle backspace: clear the current input and move focus to the previous input
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      otpInputs[index - 1].current.focus();
    }
  };
  
  
  
  // const handleSubmite = async (e)=>{
  //   e.preventDefault();
  //   setIsLoading(true);
  //   navigate('/checkout-success')
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const enteredOTP = otp.join('');
    const correctOTP = '0000'; 
  
    if (enteredOTP === correctOTP) {
      navigate('/checkout-success');
    } else {
      setIsLoading(false);
      toast.error('Incorrect OTP');
    }
  };
  
  
  return (
    <>
      <Layout pathback='/checkout'>
                <div className='input_wrapper'>
                  {
                      selectedBank.name ==="Card" || !selectedBank.name   ?
                      <div className='card-details'>
                          <h1 className='card_h'>Enter Card PIN</h1>
                          <p className='card_p'>Please enter your card 4-digit pin to authorise this payment</p>
                          <form className='card_pin_form' onSubmit={handleSubmit}>
                              <div className='card_pin_container'>
                              {otp.map((digit, index) => (
                                  <input
                                  key={index}
                                  type='numeric'
                                  maxLength="1"
                                  className='pin'
                                  value={digit}
                                  onChange={(e) => handleInputChange(e, index)}
                                  onKeyDown={(e) => handleInputKeyDown(e, index)}
                                  ref={otpInputs[index]}
                                  autoFocus={index === 0}
                                  required
                                  />
                              ))}
                              </div>
                              <button  className='btn'>
                                {
                                  isLoading ? (
                                    <BeatLoader
                                    color="#FFF"
                                    cssOverride={{}}
                                  />
                                    
                                  ) : (
                                    <>
                                      Confirming Details
                                    </>
                                  )
                                }
                                </button>
                          </form>
                      </div>:
                      renderContent()
                  }
          </div>
      
      </Layout>
        <div className='foot-note'>You are currently in Sandbox mode. Credentials » otp: 0000</div>
        <ToastContainer className="custom-toast-container" />
    </>

  )
}

export default CardDetails
