import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function BVNConsent() {
// eslint-disable-next-line
  const navigate = useNavigate();
  const [selectedCurrency, setSelectedCurrency] = useState("+234");

//   const handleBack = () => {
//     navigate('/pages/mandate-confirmation');
//   };

const handleClick = () => {
    navigate('/pages/enter-otp');
    }
const handleSelectCurrency = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const flagImage = () => {
    switch (selectedCurrency) {
      case '+234':
        return <img src={`/images/flag.svg`} alt='icon' />;
      case '+256':
        return <img src={`/images/uganda.svg`} alt='icon' />;
      case '+254':
        return <img src={`/images/kenya.svg`} alt='icon' />;
      case '+233':
        return <img src={`/images/ghana.svg`} alt='icon' />;
      default:
        return null;
    }
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
         <div className='verify-bvn'>

         <div className="identity-verify">
            <div>
                <img src={process.env.PUBLIC_URL + '/images/flickLogo.svg'} alt="logo"/>
            </div>
            <p className='will-send'>We will send an OTP to verify your identity</p>
          </div>

            <div className='otp-verify'>
                <div className='phone-email' style={{borderBottom:"1px solid #EAECF0"}} onClick={handleClick}>
                    <div className='verify-otp'>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/phone-otp.svg'} alt="logo"/>
                        </div>
                        <div className='verify-mode'>0813***2667</div>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/arrow-right.svg'} alt="logo"/>
                </div>

                <div className='phone-email' onClick={handleClick}>
                    <div className='verify-otp'>
                        <div>
                         <img src={process.env.PUBLIC_URL + '/images/email-otp.svg'} alt="logo"/>
                        </div>
                        <div className='verify-mode'>ayo***e@getflick.app</div>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/arrow-right.svg'} alt="logo"/>
                </div>
            </div>

            <div className='other'>
                <div className='or-line'></div>
                <p className='or'>Or</p>
                <div className='or-line'></div>
            </div>

            <div className='mobile-number'>
                <p className='your-current'>Enter your current banking mobile number below</p>

                <div>
                    <div className="select-code">
                    <div className='flag-code'>
                    {flagImage()}
                        <select value={selectedCurrency} onChange={handleSelectCurrency} className='input-select-code'>
                        <option value='+234'>+234</option>
                        <option value='+256'>+256</option>
                        <option value='+254'>+254</option>
                        <option value='+233'>+233</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        className="input-phone-number"
                    />
                    </div>
              </div>
            </div>
         </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default BVNConsent;
