import React, {useState} from 'react'
import { BeatLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../connectAccounts/connectAccount.scss'

function FlickLink() {

    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState('');

    const initialState = {
      country: '',
      language: '',
      product: '',
    };

    // eslint-disable-next-line
    const [formData, setFormData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isInputClicked, setIsInputClicked] = useState({
    country: false,
    language: false,
    product: false,
  });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
        if (name === 'product') {
          setSelectedProduct(value);
          localStorage.setItem('selectedProduct', value);
        }
      };
    
      const handleInputContainerClick = (inputName) => {
        setIsInputClicked((prevState) => ({
          ...prevState,
          [inputName]: true,
        }));
      };
    
      const handleInputBlur = (inputName) => {
        setIsInputClicked((prevState) => ({
          ...prevState,
          [inputName]: false,
        }));
      };

      const handleLaunchDemo = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (selectedProduct === 'direct-debit-open') {
          navigate('/pages/debit-mandate-confirmation');
      } else if (selectedProduct === 'direct-debit') {
          navigate('/pages/direct-super-wallet');
      } else {
          navigate('/pages/super-wallet', { state: selectedProduct });
      }
      
      }

  return (
  <>
        <div className='launch-demo'>
            <div className="connect-account-overlay">
            <div className="connect-account-main" style={{paddingTop:"30px"}}>
            <div className='flick-link-product'>
                <h1 className='flick-product-tour'>Flick <span style={{color:"#2EBDB6", textDecoration:"underline", textUnderlineOffset:"10px"}}>Link Demo</span></h1>
                <p className='discover'>— Discover how our solutions can help you onboard more<br/> customers, request financial data and collect payments</p>
            </div>
            <div className="flick-link-box" style={{width:"50%", maxWidth:"497px"}}>
                <div className="flick-link-title" style={{borderBottom:"none"}}>
                <div className="flick-link" style={{display:"flex", alignItems:"flex-start", paddingLeft:"24px"}}>
                    <p className="link-demo">Product option</p>
                    <p className="explore-flick">Please select a preferred payment option</p>
                </div>
                </div>
                <form className="flick-link-form" onSubmit={handleLaunchDemo}>
                <div className="flick-link-inner">
                    <div className="input_container" onBlur={() => handleInputBlur('country')}>
                    <div className={`input-group ${isInputClicked.country ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('country')}>
                        <select onChange={handleInputChange} className='link_select_custom' required>
                            <option value="Nigeria"> Nigeria </option>
                            <option value="Kenya"> Kenya </option>
                            <option value="Rwanda"> Rwanda </option>
                        </select>
                    </div>
                    <p className={`input-label ${isInputClicked.country ? 'clicked' : ''}`}>Country </p>
                    </div>
                    <div className="input_container" onBlur={() => handleInputBlur('language')}>
                    <div className={`input-group ${isInputClicked.language ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('language')}>
                        <select onChange={handleInputChange} className='link_select_custom' required>
                            <option value="english"> English </option>
                        </select>
                    </div>
                    <p className={`input-label ${isInputClicked.language ? 'clicked' : ''}`}>Language </p>
                    </div>
                    <div className="input_container" onBlur={() => handleInputBlur('product')}>
                    <div className={`input-group ${isInputClicked.product ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('product')}>
                        <select onChange={handleInputChange} className='link_select_custom' name="product" required defaultValue="">
                            <option value="" disabled hidden> Select Product </option>
                            <option value="transactions"> Transactions </option>
                            <option value="balance"> Balance </option>
                            <option value="statement"> Statement </option>
                            <option value="identity"> Identity </option>
                            <option value="direct-debit"> Direct Debit </option>
                            <option value="direct-debit-open"> Direct Debit (Open Banking) </option>
                        </select>
                    </div>
                    <p className={`input-label ${isInputClicked.product ? 'clicked' : ''}`}>Product </p>
                    </div>
                </div>
                <button id="continue-button" className="btn" style={{ background: '#2EBDB6' }} disabled={isLoading}>
                    {isLoading ? (
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
                    ) : (
                    <>
                        Launch Demo
                        <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }} />
                    </>
                    )}
                </button>
                </form>
            </div>
            </div>
            <div className='foot-note'>You’re currently in the sandbox mode</div>
            <ToastContainer />
        </div>
        </div>
  </>
  )
}

export default FlickLink