import React, { useState } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast} from "react-toastify";

function VerifyDocument() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);


  const handleClick = async () => {
    if (!selectedDocument) {
      toast.error("Please select a document option");
      return;
    }
    setIsLoading(true)
    navigate("/pages/verification", { state: { selectedDocument } })
  };
  const handleDocumentChange = (event) => {
    setSelectedDocument(event.target.value);
  };
  const handleBack = () => {
    navigate("/pages/verify-identity");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{paddingTop:"152px"}}>
        <div className="connect-account-box">
          <div className="choose-bank" style={{width:"100%", padding:"17px 15px 0px 134.9px"}}>
            <img src={process.env.PUBLIC_URL + "/images/flick.svg"} alt="id"/>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
          <div className="associated-account" style={{marginTop:"16px"}}>
            <p className="document-verify">Select a document to verify</p>
          </div>
          <div style={{ padding: "32px 21px 21px 21px" }}>
            <div className="document-types">
                <div className="document-type" onClick={() => setSelectedDocument('vNIN')}>
                    <input type="radio" name="options" value="vNIN" checked={selectedDocument === 'vNIN'} onChange={handleDocumentChange} className="custom-radio" />
                    <p className="choose-document">vNIN</p>
                </div>
                <div className="document-type" onClick={() => setSelectedDocument('Voter’s ID No.')}>
                    <input type="radio" name="options" value="Voter’s ID No." checked={selectedDocument === 'Voter’s ID No.'} onChange={handleDocumentChange} className="custom-radio"/>
                    <p className="choose-document">Voter’s ID No.</p>
                </div>
                <div className="document-type" onClick={() => setSelectedDocument('Driver’s License No.')}>
                    <input type="radio" name="options" value="Driver’s License No." checked={selectedDocument === 'Driver’s License No.'} onChange={handleDocumentChange} className="custom-radio"/>
                    <p className="choose-document">Driver’s License No.</p>
                </div>
                <div className="document-type" onClick={() => setSelectedDocument('International Passport No.')}>
                    <input type="radio" name="options" value="International Passport No." checked={selectedDocument === 'International Passport No.'} onChange={handleDocumentChange} className="custom-radio"/>
                    <p className="choose-document">International Passport No.</p>
                </div>
            </div>
            <button className="btn" style={{ fontSize:"14px", background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              
                  ) : (
                        <>
                        Choose a document                
                        </>
                    )}
            </button>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default VerifyDocument;
