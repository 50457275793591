import React, { useState } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate, useLocation} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer} from "react-toastify";

function Verification() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const selectedDocument = location.state?.selectedDocument;

  const withoutNo = selectedDocument?.replace("No.", "");
  const handleClick = async () => {
    setIsLoading(true)
    navigate("/pages/selfie-check")
  };
 
  const handleBack = () => {
    navigate("/pages/verify-document");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{paddingTop:"152px"}}>
        <div className="connect-account-box">
          <div className="choose-bank" style={{width:"100%", padding:"17px 15px 0px 134.9px"}}>
            <img src={process.env.PUBLIC_URL + "/images/flick.svg"} alt="id"/>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
          <div className="associated-account" style={{marginTop:"16px"}}>
            <p className="document-verify">{withoutNo} verification</p>
          </div>
          <div style={{ padding: "32px 21px 21px 21px" }}>
            <form onSubmit={handleClick}>
              <div className="input-verify">
                <label className="enter-number">Enter {selectedDocument}</label>
                <div className="input-group">
                <input type="text" placeholder="08236902667" name="verify" className="verify-input" required/>
                </div>
              </div>
            
              <button className="btn" style={{ fontSize:"14px", background: "#2EBDB6" }} disabled={isLoading}>
              {isLoading ? (
                    <BeatLoader
                      color="#FFF"
                      cssOverride={{}}
                    />              
                    ) : (
                          <>
                          Verify Identity                
                          </>
                      )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default Verification;
