import React, { useEffect } from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
import Layout from '../checkout/layout/Layout';
import ConfirmBankTransfer from '../checkout/bankTransfer/ConfirmBankTransfer';


function Transfer() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    const auth = JSON.parse(localStorage.getItem("auth"))
    if(!auth && !location?.state?.transferData) return navigate('/')  
  // eslint-disable-next-line
  },[])
  const  {transferData} =  location?.state || {}
  return (
    <Layout pathback="/pages/landing">
      <ConfirmBankTransfer transferData={transferData} />
  </Layout>
  )
}

export default Transfer
