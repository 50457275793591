import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";

 function ConnectSuccessful() {

  const navigate = useNavigate();
  // eslint-disable-next-line 
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line 
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  // eslint-disable-next-line 
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    


  const handleFinish = async () => {
    setIsLoadingLink(true);
    navigate("/pages/setup-complete");
  }
  
  const handleContinue = () => {
    setIsLoading(true)
    navigate("/pages/another-link");
  }

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className='success-flick' style={{marginTop:"41px"}}>
            <img src={process.env.PUBLIC_URL + "/images/connect-success.svg"} alt="logo"/>
            {/* <img src={process.env.PUBLIC_URL + "/images/ass-zenith.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/> */}
          </div>

          <div className='connected' style={{marginBottom:"0"}}>
            <p className='account-success' style={{width:"100%"}}>You have successfully connected a minimum of 2 accounts as requested by RaveLoan</p>
          </div>
            <div className='connected-btn'> 
              <button className="btn" onClick={handleFinish} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingLink ? (
                  <BeatLoader
                    color="#FFF"  
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Finish
                  </>
                )}
              </button>

              <button className="btn" onClick={handleContinue} style={{ background: '#FFF', color:"#161925", border: "1px solid #EAECF0"}} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingDashboard ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Link another account
                  </>
                )}
              </button>
            </div>
        </div>
      </div>
        <div className='foot-note'>You’re currently in the sandbox mode</div>
    </div>
  )
}

export default ConnectSuccessful;

