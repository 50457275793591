import React, {useState} from 'react'
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../connectAccounts/connectAccount.scss'
import SelectDropDown from '../checkout/landing/SelectDropDown';
import { BounceLoader } from 'react-spinners';
import Card from '../checkout/card/Card';
import BankTransfer from '../checkout/bankTransfer/BankTransfer';
import styled from 'styled-components';
import Layout from '../checkout/layout/Layout';

const OverlaySlider = styled.div`
position:"absolute";
top:0;
left:0;
background: rgba(255, 255, 255, 0.8);
height: 100%;
width: 100%;
z-index: 3;
display: flex;
align-items: center;
justify-content: center;
padding-top:1rem;
`
const ErrorMessage = styled.div`
width:100%;
height:100%;
padding-top:1rem;
z-index: 3;
display: flex;
align-items: center;
justify-content: center;
margin-top:1rem;
background:#F7FCFC;
padding: 1rem;
.error_message{
  color: var(--grey-900, #101928);
  font-family: Circular Std;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
`

function Checkout() {
// eslint-disable-next-line
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
    // eslint-disable-next-line
    const [transferData,setTransferData] = useState({})
    // eslint-disable-next-line
    const [isLoading,setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [erro,setError] = useState(false);

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    const handleBankSelection = (bankName) => {
      setSelectedBank(bankName);
      closeModal();
    };

  const renderContent = ()=>{
    if(selectedBank.name==="Card"){
      return <Card/>
    } 
}

  return (
  <>
      <Layout landing pathback='/checkout-landing'>
    
        <div className='input_wrapper'>
                <div className="input-container" onClick={openModal}>
                {selectedBank ? (
                    <>
                    <h3 className="bank-name">{selectedBank.name}</h3>
                    </>
                ) : (
                    <span >Choose option</span>
                )}
                <img
                    src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
                    alt="arrow"
                    style={{marginLeft:"auto"}}
                />
            </div>

            {isModalOpen && (
            <SelectDropDown
                handleBankSelection={handleBankSelection}
                closeModal={closeModal}
                selectedBank={selectedBank}
            />
            )}
            
            {renderContent()}
            {
            isLoading ? 
            (
            <OverlaySlider>
                <BounceLoader color="#ABEFC6" />
            </OverlaySlider>
            ) : erro? (
                <ErrorMessage>
                <h1 className='error_message'>An Error Has Occured: Please Try Again..</h1>
                </ErrorMessage>
            ):

            (selectedBank.name === 'Transfer' && transferData && <BankTransfer transferData={transferData} />)
            }
            { selectedBank.name === "Flick  one-click" || selectedBank.name === "Pay by Bank" ? <ErrorMessage><h1 className='error_message'>Coming Soon!!!</h1></ErrorMessage>:""}
        </div>

          <ToastContainer />
      </Layout>
       <div className='foot-note'>You’re currently in the sandbox mode</div>
  </>
  )
}
export default Checkout