import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';

function MicroDeposit() {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    const selectedBankIcon = localStorage.getItem('selectedBankIcon');

    const handleBack =() => {
      // navigate('/pages/choose-authorization');
      navigate('/pages/mandate-confirmation');
    }

    const handleSend = () => {
        navigate('/pages/setup-complete');
    }
    const handleCancel = () => {
        // navigate('/pages/choose-authorization');
        navigate('/pages/mandate-confirmation');
    }

    return (
        <div className="connect-account-overlay">
          <div className="connect-account-main">
            <div className="connect-account-box">
              <div className="choose-bank">
                <img
                  src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
                  alt="back"
                  style={{ width: '16px', height: '16px' }}
                  onClick={handleBack}
                />
                <p className="choose-your-bank">Authorise via micro-deposit</p>
                <img
                  src={process.env.PUBLIC_URL + '/images/close.svg'}
                  alt="close"
                  onClick={handleBack}
                />
              </div>
              <div className="flick-logo" style={{ position: 'relative'}}>
                <img
                  src={process.env.PUBLIC_URL + selectedBankIcon}
                  alt="bank logo"
                  style={{width: "47px", height: "48.958px"}}
                />
              </div>
              <div className="connect-effortless" style={{gap:"0px"}}>
                <div className='transfer'>
                  <div className='transfer-to'>
                    <p className='transfer-amount'>Transfer NGN 50.00 to GTBank</p>
                    <div className='authorization'>
                        <div className='authorize'>
                            <p className='nibss'>NIBSS AUTHORISATION</p>
                            <p className='account-copy'>0224048917 <span><img src={process.env.PUBLIC_URL + '/images/copy.svg'} alt="copy"/></span></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + selectedBankIcon} alt="icon" style={{width: "24.96px", height: "26px"}}/>
                        </div>
                    </div>
                  </div>
                    <p className='transfer-only'>This account is for this transfer only. It expires in 09:45</p>
                </div>
                <div className='buttons'>
                  <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleSend} disabled={isLoading || isLoadingCancel}>
                    {isLoading ? (
                      <BeatLoader color="#FFF" cssOverride={{}} />
                    ) : (
                      <>
                        I’ve sent the money
                      </>
                    )}
                  </button>
                  <button className="btn" style={{ background: '#FFF', color:"#161925", border: "1px solid #EAECF0" }} onClick={handleCancel} disabled={isLoading || isLoadingCancel}>
                    {isLoadingCancel ? (
                      <BeatLoader color="#FFF" cssOverride={{}} />
                    ) : (
                      <>
                        Cancel
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <div className='foot-note'>You’re currently in the sandbox mode</div>
        </div>
      );
}

export default MicroDeposit