import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import LoginDetails from './components/connectAccounts/LoginDetails';
import SuperWallet from './components/connectAccounts/SuperWallet';
import SelectBanks from './components/connectAccounts/SelectBanks';
import ChooseYourBank from './components/connectAccounts/ChooseYourBank';
import EnterCredentials from './components/connectAccounts/EnterCredentials';
import ConnectAccounts from './components/connectAccounts/ConnectAccounts';
import AccountConnected from './components/connectAccounts/AccountConnected';
import NotFoundPage from './components/pages/NotFoundPage';
import InvalidPage from './components/pages/InvalidPage';
import Landing from './components/pages/Landing';
import FlickLink from './components/pages/FlickLink';
import SuccessPage from './components/pages/SuccessPage';
import AssociatedAccounts from './components/connectAccounts/AssociatedAccounts';
import AssociatedChooseYourBank from './components/connectAccounts/AssociatedChooseYourBank';
import AssociatedCredentials from './components/connectAccounts/AssociatedCredentials';
import VerifyIdentity from './components/kycOnboarding/newUserNonDoc/VerifyIdentity';
import VerifyDocument from './components/kycOnboarding/newUserNonDoc/VerifyDocument';
import Verification from './components/kycOnboarding/newUserNonDoc/Verification';
import SelfieCheck from './components/kycOnboarding/newUserNonDoc/SelfieCheck';
import AlignHead from './components/kycOnboarding/newUserNonDoc/AlignHead';
import CheckingSelfie from './components/kycOnboarding/newUserNonDoc/CheckingSelfie';
import Verified from './components/kycOnboarding/newUserNonDoc/Verified';
import FlickCheckout from './components/pages/FlickCheckout';
import Checkout from './components/pages/Checkout';
import Transfer from './components/pages/Transfer';
import Success from './components/pages/Success';
import CardDetails from './components/pages/CardDetails';
import DirectSuperWallet from './components/directdebit/DirectSuperWallet';
import DirectSelectBanks from './components/directdebit/DirectSelectBanks';
import ChooseYourAccount from './components/directdebit/ChooseYourAccount';
import LinkAccount from './components/directdebit/LinkAccount';
import MandateConfirmation from './components/directdebit/MandateConfirmation';
import ChooseAuthorization from './components/directdebit/ChooseAuthorization';
import MicroDeposit from './components/directdebit/MicroDeposit';
import SetupComplete from './components/directdebit/SetupComplete';
import BVNConsent from './components/directdebitlookup/BVNConsent';
import TransactionOtp from './components/directdebitlookup/TransactionOtp';
import ConsentReceived from './components/directdebitlookup/ConsentReceived';
import RaveLoan from './components/directdebitlookup/RaveLoan';
import DirectAssociatedAccounts from './components/directdebitlookup/DirectAssociatedAccounts';
import DirectAssociatedCredentials from './components/directdebitlookup/DirectAssociatedCredentials';
import InvalidAccount from './components/directdebitlookup/InvalidAccount';
import InputOtp from './components/directdebitlookup/InputOtp';
import DirectChooseBank from './components/directdebitlookup/DirectChooseBank';
import DirectAccountConnected from './components/directdebitlookup/DirectAccountConnected';
import DirectMandateConfirmation from './components/directdebitlookup/DirectMandateConfirmation';
import OpenDirectSelectBanks from './components/directdebitlookup/OpenDirectSelectBanks';
import DirectChooseYourAccount from './components/directdebitlookup/DirectChooseYourAccount';
import DirectLinkAccount from './components/directdebitlookup/DirectLinkAccount';
import AnotherLink from './components/directdebitlookup/AnotherLink';
import DirectAssociatedChooseYourBank from './components/directdebitlookup/DirectAssociatedChooseYourBank';
import AnotherChooseYourBank from './components/directdebitlookup/AnotherChooseYourBank';
import AnotherCredentials from './components/directdebitlookup/AnotherCredentials';
import AnotherOtp from './components/directdebitlookup/AnotherOtp';
import ConnectSuccessful from './components/directdebitlookup/ConnectSuccessful';
import ContinueLater from './components/directdebitlookup/ContinueLater';

function App() {
  return (
    <Router>
        <Routes>
          <Route>
            <Route path="/" element={<Landing />} />
            <Route path="/link" element={<FlickLink />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/invalid" element={<InvalidPage/>} />
            <Route path="/pages/:id" element={<LoginDetails />} />
            <Route path="/:id" element={<NotFoundPage />} />
            <Route path="/pages/super-wallet" element={<SuperWallet />} />
            <Route path="/pages/direct-super-wallet" element={<DirectSuperWallet />} />
            <Route path="/pages/direct-select-bank" element={<DirectSelectBanks />} />
            <Route path="/pages/choose-account" element={<ChooseYourAccount />} />
            <Route path="/pages/link-account" element={<LinkAccount />} />
            <Route path="/pages/mandate-confirmation" element={<MandateConfirmation />} />
            <Route path="/pages/choose-authorization" element={<ChooseAuthorization />} />
            <Route path="/pages/micro-deposit" element={<MicroDeposit />} />
            <Route path="/pages/setup-complete" element={<SetupComplete />} />
            <Route path="/pages/associated-account" element={<AssociatedAccounts />} />
            <Route path="/pages/associated-choose-bank" element={<AssociatedChooseYourBank />} />
            <Route path="/pages/associated-enter-credentials" element={<AssociatedCredentials />} />
            <Route path="/pages/select-bank" element={<SelectBanks />} />
            <Route path="/pages/choose-bank" element={<ChooseYourBank />} />
            <Route path="/pages/enter-credentials" element={<EnterCredentials />} />
            <Route path="/pages/connect-accounts" element={<ConnectAccounts />} />
            <Route path="/checkout-landing" element={<FlickCheckout />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/pages/card" element={<CardDetails />} />
            <Route path="/checkout-success" element={<Success />} />
            <Route path="/pages/confirm-transfer" element={<Transfer />} />
            <Route path="/pages/verify-identity" element={<VerifyIdentity />} />
            <Route path="/pages/verify-document" element={<VerifyDocument />} />
            <Route path="/pages/verification" element={<Verification />} />
            <Route path="/pages/selfie-check" element={<SelfieCheck />} />
            <Route path="/pages/align-head" element={<AlignHead />} /> 
            <Route path="/pages/checking-selfie" element={<CheckingSelfie />} />
            <Route path="/pages/verified" element={<Verified />} />
            <Route path="/pages/account-connected" element={<AccountConnected />} />

            <Route path="/pages/direct-choose-account" element={<DirectChooseYourAccount />} />
            <Route path="/pages/direct-link-account" element={<DirectLinkAccount />} />
            <Route path="/pages/open-direct-select-bank" element={<OpenDirectSelectBanks/>} />
            <Route path="/pages/debit-mandate-confirmation" element={<DirectMandateConfirmation />} />
            <Route path="/pages/bvn-consent" element={<BVNConsent />} />
            <Route path="/pages/enter-otp" element={<TransactionOtp />} />
            <Route path="/pages/consent-received" element={<ConsentReceived />} />
            <Route path="/pages/rave-loan" element={<RaveLoan />} />
            <Route path="/pages/open-associated-choose-bank" element={<DirectAssociatedChooseYourBank/>} />
            <Route path="/pages/direct-accounts" element={<DirectAssociatedAccounts/>} />
            <Route path="/pages/direct-enter-credentials" element={<DirectAssociatedCredentials />} />
            <Route path="/pages/invalid" element={<InvalidAccount />} />
            <Route path="/pages/input-otp" element={<InputOtp />} />
            <Route path="/pages/direct-choose-bank" element={<DirectChooseBank/>} />
            <Route path="/pages/connected" element={<DirectAccountConnected/>} />
            <Route path="/pages/choose-authorization" element={<ChooseAuthorization />} />
            <Route path="/pages/micro-deposit" element={<MicroDeposit />} />
            <Route path="/pages/another-link" element={<AnotherLink />} />
            <Route path="/pages/another-choose-bank" element={<AnotherChooseYourBank />} />
            <Route path="/pages/another-credentials" element={<AnotherCredentials />} />
            <Route path="/pages/another-bank-otp" element={<AnotherOtp />} />
            <Route path="/pages/connect-successful" element={<ConnectSuccessful />} />
            <Route path="/pages/continue-later" element={<ContinueLater />} />
            <Route path="/pages/setup-complete" element={<SetupComplete />} />
          </Route>
        </Routes>
    </Router>
  );
}

export default App;