import React, { useState, useEffect } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function DirectAssociatedCredentials() {
  const navigate = useNavigate();
  
  const selectedBankCode = localStorage.getItem('selectedBankCode');
  // eslint-disable-next-line
  const bankingType = localStorage.getItem('bankingType') || 'PERSONAL_BANKING';
  // eslint-disable-next-line
  const selectedOption = localStorage.getItem('selectedOption') || 'internet_banking';
   // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [id, setId] = useState('');
  // eslint-disable-next-line
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // eslint-disable-next-line
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false);
  const selectedBank = localStorage.getItem('selectedBank');

  useEffect(() => {
    console.log('Selected Bank Code:', selectedBankCode);
    const savedId = localStorage.getItem('sessionId');
    if (savedId) {
      setId(savedId);
    }
  }, [selectedBankCode]);

  const handleBack = () => {
    navigate('/pages/open-associated-choose-bank');
  };

  const initialState = {
    bank_code: selectedBankCode,
    code_password: '',
    id:'',
    username: '',
    password: ''
  };

  const [formData, setFormData] = useState(initialState);
  const [isInputClicked, setIsInputClicked] = useState({
    username: false,
    password: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.username === '' || formData.password === '') {
      toast.error('Kindly input the credentials');
      return;
    }
    if (formData.username !== 'flick_test' || formData.password !== 'flick_testing') {
      toast.error('Incorrect credentials');
      return;
    }
    if (selectedBank === 'kuda') {
      navigate('/pages/invalid');
    } else {
      navigate('/pages/input-otp');
    }
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '27px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
          <div className='img-logo'>
              {selectedBank === 'gtb' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/gtbank.svg'}
                  alt="GTBank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'zenith' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/zenith-bank.svg'}
                  alt="Zenith Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'kuda' && (
                <img
                  src={process.env.PUBLIC_URL + 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/kuda-bank-icon.png'}
                  alt="Kuda Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
            </div>
            <div className="enter-credentials">
              <p className="enter-your-credentials">Enter your credentials</p>
            </div>
            <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>
              <div className="flick-link-inner">
                <div className="input_container" onBlur={() => handleInputBlur('username')}>
                  <div className={`input-group ${isInputClicked.username ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('username')}>
                    <input
                      type="text"
                      name="username"
                      placeholder="0223038917"
                      value={formData.username}
                      className="input-name"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <p className={`input-label ${isInputClicked.username ? 'clicked' : ''}`}>User ID/ Account/Email</p>
                </div>
              </div>
              <div className="flick-link-inner" style={{marginBottom:"12px"}}>
                <div className="input_container" onBlur={() => handleInputBlur('password')}>
                  <div className={`input-group ${isInputClicked.password ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('password')}>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      className="input-name"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <p className={`input-label ${isInputClicked.password ? 'clicked' : ''}`}>Password</p>
                </div>
              </div>
              <button className="btn" style={{ background: '#2EBDB6' }} type="submit" onClick={handleSubmit} disabled={isLoading} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Submit
                    <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                  </>
                )}
              </button>
              <div className="secured-flick">
                <img src={process.env.PUBLIC_URL + '/images/secured-by-flick.svg'} alt="flick" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='foot-note'>You are currently in Sandbox mode. Credentials » username: flick_test | password: flick_testing</div>
      <ToastContainer className="custom-toast-container" />
      {/* <div className='foot-note'>
                {isOtpModalOpen
                    ? "You are currently in Sandbox mode. Credentials » otp: 00000"
                    : "You are currently in Sandbox mode. Credentials » username: flick_test | password: flick_testing"
                }
            </div>
      {isOtpModalOpen ? <OtpModal /> : isSecretModalOpen ?  <SecretModal /> :  null} */}
    </div>
  );
}

export default DirectAssociatedCredentials;
