import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
// import PouchDB from 'pouchdb';

 function AccountConnected() {

  const navigate = useNavigate();
    // const db = new PouchDB('FlickPouchDb');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
//   const [isPortco, setIsPortco] = useState(false);

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);

    // const publicKeyDoc = db.get('flickmydoc');
    // const isPortcoed = publicKeyDoc.is_portco;
    const isPortcoed = localStorage.getItem("is_portco") === "yes" ? true : false;
    // isPortcoed === true ? setIsPortco(true) : setIsPortco(false)
    

  const handleDashboard = () => {
    setIsLoadingDashboard(true);
    window.location.href = 'https://portco.merchant.getflick.co';
  }
  const linkId = localStorage.getItem('linkId')
  const handleLinkAccount = async () => {
    setIsLoadingLink(true);
    // window.location.href = 'https://link.paywithflick.co/pages/79mJS8A3sx';
    window.location.href = `https://link.paywithflick.co/pages/${linkId}`;
    

  }
  const handleContinue = () => {
    setIsLoading(true)
    navigate("/success");
  }

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className='success-flick'>
            <img src={process.env.PUBLIC_URL + "/images/success-icon.svg"} alt="logo"/>
            <img src={process.env.PUBLIC_URL + "/images/flick-white-border.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/>
          </div>

          <div className='connected'>
            <p className='account-connected'>Account Connected!</p>
            <p className='your-account'>Your account has been successfully linked, you can now close this page.</p>
          </div>
          {isPortcoed && (
            <div className='portco-btn'>
              <button className="btn-account" onClick={handleLinkAccount} style={{ background: '#2EBDB6' }} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingLink ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Link another account
                  </>
                )}
              </button>

              <button className="btn-account" onClick={handleDashboard} style={{ background: '#EAF8F8', color:"#2EBDB6" }} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingDashboard ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Go to dashboard
                  </>
                )}
              </button>
            </div>
          )}

          <div className='portco-btn'>
            <button className="btn-account" onClick={handleContinue} style={{ background: '#2EBDB6' }}>
              {isLoading ? (
                <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
              ) : (
                <>
                  Continue <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px", width:"14px", height:"14px"}}/>

                </>
              )}
            </button>
          </div>
        </div>
      </div>
        <div className='foot-note'>You’re currently in the sandbox mode</div>
    </div>
  )
}

export default AccountConnected;

