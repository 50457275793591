import React, { useEffect, useState } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function AlignHead() {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(1);

  const handleBack = () => {
    navigate("/pages/selfie-check");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {

      setCurrentImage((prevImage) => (prevImage % 4) + 1);
    }, 1000);

    const navigationTimeoutId = setTimeout(() => {
      navigate("/pages/checking-selfie");
    }, 4000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(navigationTimeoutId);
    };
  }, [navigate]);

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{ paddingTop: "152px" }}>
        <div className="connect-account-box">
          <div
            className="choose-bank"
            style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              onClick={handleBack}
            />
          </div>

          <div style={{ padding: "54.2px 21px 21px 21px" }}>
            <div className="face-id">
              <img
                src={process.env.PUBLIC_URL + `/images/align-head-${currentImage}.svg`}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default AlignHead;
