import React, { useState } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer} from "react-toastify";

function VerifyIdentity() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true)
    navigate("/pages/verify-document")
  };
 
  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{paddingTop:"152px"}}>
        <div className="connect-account-box">
          <div className="choose-bank" style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div className="associated-account" style={{marginTop:"19px"}}>
            <img style={{width:"48px", height:"48px"}} src={process.env.PUBLIC_URL + "/images/ass-flick.svg"} alt="logo"/>
            <p className="verify-identity">Let’s verify your identity</p>
            <p className="we-found">To get verified, you will need:</p>
          </div>
          <div style={{ padding: "0px 21px 21px 21px" }}>
            <div className="account-type-container" style={{borderBottom: "1px solid #EAECF0"}}>
                <div className="account-type" style={{border:"none", padding:"0 0 21px 0"}}>
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row", gap:"16px"}}>
                    <div>
                        <img src={process.env.PUBLIC_URL + "/images/id-card.svg"} alt="id"/>
                    </div>
                    <div>
                        <p className="verification">BVN</p>
                        <p className="verify-info">Upload photos of documents proving your Identity.</p>
                    </div>
                  </div>
                </div>
            </div>

            <div className="account-type-container" style={{borderBottom: "1px solid #EAECF0"}}>
                <div className="account-type" style={{border:"none", padding:"0 0 21px 0"}}>
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row", gap:"16px"}}>
                  <div>
                    <img src={process.env.PUBLIC_URL + "/images/id-card.svg"} alt="logo"/>
                  </div>
                  <div>
                    <p className="verification">ID Number</p>
                    <p className="verify-info">Upload photos of documents proving your Identity.</p>
                  </div>
                  </div>
                </div>
            </div>

            <div className="account-type-container" style={{marginBottom:"30px"}}>
                <div className="account-type" style={{border:"none", padding:"0"}}>
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row", gap:"16px"}}>
                    <div>
                      <img src={process.env.PUBLIC_URL + "/images/selfie.svg"} alt="logo"/>
                    </div>
                    <div>
                      <p className="verification">Selfie</p>
                      <p className="verify-info">Record a short selfie video of yourself.</p>
                    </div>
                  </div>
                </div>
            </div>
            <button className="btn" style={{ fontSize:"14px", background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              
                  ) : (
                        <>
                        Start                
                        </>
                    )}
            </button>
            <div className='agree-to' style={{marginBottom:"0", marginTop:"10px"}}>
                <p className='select-continue'>By selecting “Continue” you agree to the</p>
                <a href="https://getflick.app/privacy-policy" className='flick-end-user'>Flick End User Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default VerifyIdentity;
