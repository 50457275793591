import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";

function MandateConfirmation() {
  const navigate = useNavigate();
  // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        // navigate('/pages/choose-authorization');
        navigate('/pages/micro-deposit');
    }

    const handleBack = () => {
        navigate('/pages/link-account');
      };
  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img src={process.env.PUBLIC_URL + "/images/arrowBack.svg"} alt="back" style={{ width: "16px", height: "16px" }} onClick={handleBack}/>
            <p className="choose-your-bank">Mandate confirmation</p>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
          <div className="mandate">
            <p className="setup-mandate">Setup mandate with Flick for</p>
            <h4 className="mandate-amount">NGN 50,000</h4>
          </div>
          <div className="connect-effortless" style={{gap:"0px"}}>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Type</p>
                    <p className="light-text">Validity</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">Fixed mandate</p>
                    <p className="dark-text">6 months</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">First debit</p>
                    <p className="light-text">Start date</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">NGN 50,000.00</p>
                    <p className="dark-text">15 Oct, 2023</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Total debit</p>
                    <p className="light-text">End date</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">NGN 300,000.00</p>
                    <p className="dark-text">15 March, 2024</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Reference</p>
                    <p className="light-text">Frequency</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">flick_3525fs353e53gw3</p>
                    <p className="dark-text">Monthly</p>
                </div>
            </div>
          </div>
          <div className="flick-policy">
            <div className="agree-to">
              <p className="select-continue">To setup this mandate, you need to authorise</p>
              <p className="flick-end-user" style={{textDecorationLine:"none"}}>Flick Direct Debit</p>
            </div>
            <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleClick} disabled={isLoading}>
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'} alt="arrow btn" style={{ display: 'inline', marginLeft: '5px' }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className='foot-note'>You’re currently in the sandbox mode</div>
    </div>
  );
}

export default MandateConfirmation;
