import React, {useState, useEffect} from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer} from "react-toastify";

function Verified() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);

  const handleClick = async () => {
    setIsLoading(true)
    navigate("/")
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{paddingTop:"152px"}}>
        <div className="connect-account-box">
          <div className="choose-bank" style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
         
          <div style={{ padding: "15px 21px 21px 21px" }}>
            <div className="face-id" style={{marginBottom:"60px"}}>
                <img src={process.env.PUBLIC_URL + "/images/verified.svg"} alt="img"/>
            </div>
            <button className="btn" style={{ fontSize:"14px", background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              
                  ) : (
                        <>
                        Finish                
                        </>
                    )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Verified;