import React, { useState, useRef } from 'react'
import { copyToClipboard } from '../utilities/clipboardUtils'
import './banktransfer.scss'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

// data displayed when show account is clicked on confirmation //
function BankTransfer({ hideSendMoneyButton,transferData}) {
  const [bank, setBank] = useState("");

    
    const refcopy  = useRef(null);

    const navigate = useNavigate();
    const handleSelectBank = (e) => {
      setBank(e.target.value);
    };

    const bankIcon = () => {
      switch (bank) {
        case 'GTBank':
          return <img src={`/images/gt-icon.svg`} alt='icon' />;
        case 'Kuda Bank':
          return <img src={`/images/kuda-icon.svg`} alt='icon' />;
        case 'FCMB':
          return <img src={`/images/fcmb-icon.svg`} style={{borderRadius:"50%"}} alt='icon' />;
        case 'VFD MFB':
          return <img src={`/images/vfd-icon.svg`} style={{borderRadius:"50%"}} alt='icon' />;
        default:
          return null;
      }
    };
  const handleClick = () => {
    if (!bank) {
      toast.error('Please select a bank');
      return;
    }
    navigate("/pages/confirm-transfer",{state:{transferData}});
  };
  return (
    <div className='bank_transfer'>
      <div className='rule'></div>
      <div className='bank-options'>
        <h3 className='bank_transfer_title'>Bank details</h3>
        <div className='icon-bank'>
        {bankIcon()}
        <select className='bank-option'value={bank} onChange={handleSelectBank}>
          <option value="">Select bank</option>
          <option value="GTBank">GTBank</option>
          <option value="Kuda Bank">Kuda Bank</option>
          <option value="FCMB">FCMB</option>
          <option value="VFD MFB">VFD MFB</option>
        </select>
        </div>
      </div>
       <div className='bank_transfer_content'>
         <div className='bank_row '>
            <span className='bank_light'>Account number</span>
            <span className='bank_light'>Bank</span>
         </div>
         <div style={{marginBottom:"20px"}} className='bank_row'>
            <div className='copy_div'>
                <input ref={refcopy} type='text' value="2210434827" className='copyInput'disabled/>
                <img onClick={copyToClipboard.bind(null,refcopy)} src="/images/copy.svg" alt='copy'/>
            </div>
            <span className='bank_thick'>{bank}</span>
         </div>

         <div className='bank_row '>
            <p className='bank_light'>Account type</p>
            <p className='bank_light'>Account name</p>
         </div>

         <div className='bank_row'>
            <span><img src='/images/current.svg' alt='badge' /></span>
            <span className='bank_thick'>Flick Checkout</span>
         </div>

       </div>
       <div style={{display:"flex", padding:"0 55px 0 55px"}}>
        <p className='bank_time'>This account details is only valid for this specific transaction and it’ll expire by <span style={{color:"#2EBDB6"}}>01:18 PM</span> </p>
       </div>

       {!hideSendMoneyButton && (
          <button className="btn" onClick={handleClick}>
            I’ve sent the money
          </button>
        )}
        <ToastContainer />
    </div>
  )
}

export default BankTransfer
