import React, { useState, useEffect } from "react";
import "../../connectAccounts/connectAccount.scss";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function CheckingSelfie() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const handleBack = () => {
    navigate("/pages/selfie-check");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {

      setProgress((prevProgress) => {
        const newProgress = prevProgress + 20;
        return newProgress <= 100 ? newProgress : prevProgress;
      });
    }, 1000); 

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      navigate("/pages/verified");
    }
  }, [progress, navigate]);

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main" style={{ paddingTop: "152px" }}>
        <div className="connect-account-box">
          <div
            className="choose-bank"
            style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              onClick={handleBack}
            />
          </div>

          <div style={{ padding: "84.5px 21px 21px 21px" }}>
            <div className="face-id" style={{marginBottom:"24px"}}>
              <img
                src={process.env.PUBLIC_URL + "/images/checking-selfie.svg"}
                alt="img"
              />
            </div>
            <div style={{display:"flex", alignContent:"center", justifyContent:"center", gap:"12px", marginBottom:"82px"}}>
                <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                </div>
                <div className="progress-label">{progress > 0 && `${progress}%`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='foot-note'>You’re currently in the sandbox mode</div>
      <ToastContainer />
    </div>
  );
}

export default CheckingSelfie;
