import React, { useState, useRef } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function TransactionOtp() {

  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [otp, setOtp] = useState([""]);
  const inputRef = useRef(null);
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!isChecked); // Toggle the checkbox state
  };

  const handleBack = () => {
    navigate('/pages/bvn-consent');
  };

const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 5) {
      setOtp(value);
    }
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   navigate("/pages/consent-received");
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const hardcodedOtp = "00000";
  
    if (otp === hardcodedOtp) {
        navigate('/pages/consent-received');
    } else {
      setIsLoading(false);
        toast.error("Input the correct OTP");
        setOtp("");
    }
  };

  return (
    <div className="connect-account-overlay">
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '17px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
            <div className='img-logo'>
              <img
                src={process.env.PUBLIC_URL + '/images/round-flick-logo.svg'}
                alt="logo"
                style={{width: "47px", height: "48.958px"}}
              />
            </div>

           <div className='enter-transaction'>
            <p className='enter-otp'>Enter Transaction OTP</p>
            <p className='five-digit'>Enter the 5-digit code sent to your phone</p>
           </div>

           <div className="input-verify-otp">
            <div className="input-verify-field">
            <input
                type="password"
                name="otp"
                maxLength="5"
                className="-otp"
                value={otp}
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
            <p className="code">
              Didn't get a code? <span className="click"> Click to resend.</span>
            </p>
          </div>

          <div className='check-policy'>
            <input 
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="custom-checkbox"
                style={{padding:"5px", width:"15px", height:"15px"}}
            />
           <p className='proceeding'>By “proceeding” you agree to the <span><a href="https://getflick.app/privacy-policy" className='end-user'>Flick End User Privacy Policy</a></span></p>
          </div>

            <button className="btn" onClick={handleSubmit} disabled={isLoading} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Confirm
                  </>
                )}
              </button>
          </div>
        </div>
      </div>
      <div className='foot-note'>You are currently in Sandbox mode. Credentials » otp: 00000</div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default TransactionOtp;
