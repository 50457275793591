// eslint-disable-next-line
import React, { useContext, useState } from 'react'
import './card.scss'
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
// eslint-disable-next-line
import { toast } from 'react-toastify';


function Card() {
// eslint-disable-next-line
   const navigate = useNavigate();
   
    const [formData,setFormData] = useState({
        name:"",
        card_number:"",
        card_date:"",
        card_cvv:""
    });
    const [expiry, setExpiry] = useState('');
    const [page,setPage] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const changeActive = (selected)=>{
      setPage(selected);
    } 

    const  handleChange = (event)=>{
      event.preventDefault();
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));

    }

    const handleInputChange = (e) => {
      let value = e.target.value;
      // Remove any non-digit characters
      value = value.replace(/\D/g, '');
      // Format the value with a slash
      if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }
      setExpiry(value);
    };

    const handleSubmite = async (e)=>{
      e.preventDefault();
      // eslint-disable-next-line
      setIsLoading(true);
      // eslint-disable-next-line
      const {name,card_number,card_cvv} = formData;
      // eslint-disable-next-line
      const inputValues ={name,card_number,expiry,card_cvv}
      // eslint-disable-next-line
      const data = `${card_number}|${card_cvv}|${expiry}|${name}`
      navigate('/pages/card')
    }
    
   
    
  return (
    <div className='card_container'>
      
      <div className='rule'></div>
      <h3 className='card_h'>Enter Card Information</h3>
      <form onSubmit={handleSubmite}>
        <div className='input_se'  onClick={() => changeActive("name")}>

          <input 
          className="card_name" type='text' 
          placeholder='Card Name ' 
          name="name"  
          onChange={handleChange}
          required
          />
        </div>

        <div className='input_container'>
            <div 
            onClick={() => changeActive("card_num")}
            className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
            >
            <input 
            placeholder='5061 2345 6789 1234' 
            type='numeric' 
            className='input_cardNumber' 
            name="card_number" 
            onChange={handleChange} 
            required
            />
            
            </div>
            <p className='card_label'>Card Number</p>
        </div>

        <div className='card_data_container'>
            <div 
            onClick={() => changeActive("card_date")}
            className={`card_date ${page==="card_date" ? 'clicked' : ''}`} 
            >
                <input
                 type='text' 
                 className='card_date_input' 
                 placeholder="MM/YY"  
                 id="expiry"
                 name="card_date" 
                 value={expiry}
                 maxLength="5"
                 onChange={handleInputChange} 
                 required
                />
                <img src='/images/calendar.svg' alt='date' />
            </div>
            <div onClick={() => changeActive("card_cvv")} className={`card_date ${page==="card_cvv" ? 'clicked' : ''}`}>
                <input 
                  type='numeric' 
                  placeholder='123' 
                  maxLength="3" 
                  className='card_date_input' 
                  name='card_cvv' 
                  onChange={handleChange} 
                  required
                />
                <img src='/images/lock.svg' alt='date' />
            </div>
        </div>
        <button  className='btn'>
          {
            isLoading ? (
              <BeatLoader
              color="#FFF"
              cssOverride={{}}
            />
              
            ) : (
              <>
               Pay ₦10000
              </>
            )
          }
        </button>
      </form>
    </div>
  )
}

export default Card
