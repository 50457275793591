import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import ConnectAccountModal from "../ConnectAccountModal";
import { toast } from "react-toastify";


function OtpModal() {
    console.log("i popped up modal yaaay!!!")
  const [otp, setOtp] = useState([""]);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSingleAccount] = useState(true);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 6) {
      setOtp(value);
    }
    console.log("na me enter this value ooooo " + value);
  };
  const handleOtpSubmit = async () => {
      console.log("i just sumitted otp")
    setIsLoading(true);
    const hardcodedOtp = "00000";

    if (otp === hardcodedOtp) {
        navigate('/pages/account-connected');
    } else {
      setIsLoading(false);
        toast.error("Input the correct OTP");
        setOtp("");
    }
  };

  return (
    <div className="enter-otp-modal">
      <div>
        <div className="modal-wrapper">
          <p className="enter-otp">Enter OTP</p>
          <div className="input-otp">
            <div className="input-otp-field">
              <input
                type="text"
                name="otp"
                maxLength="6"
                className="otp"
                value={otp}
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
          </div>
          <button className="otp-btn" onClick={handleOtpSubmit}>
            {isLoading ? (
              <BeatLoader color="#FFF" cssOverride={{}} />
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>
      {!isSingleAccount && <ConnectAccountModal />}
    </div>
  );
}

export default OtpModal;
