import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useNavigate } from 'react-router-dom'

function Landing() {
    const navigate = useNavigate (); 

    const handleFlickLink = () => {
        navigate("/link");
    }
    const handleFlickCheckout = () => {
        navigate("/checkout-landing");
    }
    const handleKyc = () => {
        navigate("/pages/verify-identity");
    }
  return (
    <div className='landing_page'>
        <Header />
        <div className='product-tour'>
            <h1 className='flick-product-tour'>Flick <span style={{color:"#2EBDB6", textDecoration:"underline", textUnderlineOffset:"10px"}}>Product Tour</span></h1>
            <p className='discover'>— Discover how our solutions can help you onboard more<br/> customers, request financial data and collect payments</p>
        </div>

        <div className='templates'>
            <img src={process.env.PUBLIC_URL + "/images/flick-link.svg"} alt="temp" onClick={handleFlickLink}/>
            <img src={process.env.PUBLIC_URL + "/images/flick-checkout.svg"} alt="temp" onClick={handleFlickCheckout}/>
            <img src={process.env.PUBLIC_URL + "/images/flick-kyc.svg"} alt="temp" onClick={handleKyc}/>
        </div>
        <Footer />
    </div>
  )
}

export default Landing