import React from 'react'
import './layout.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Layout(props) {
  const navigate = useNavigate()
  const handleBack = ()=>{
    navigate(props.pathback);
  }
  // eslint-disable-next-line
  const handleHome = ()=>{
    navigate('/')
  }

  return (
    <div className='layout'>
     
      {/* <div className='header'>
        <img onClick={handleHome} src="/images/Flick.svg" alt='logo'/>
      </div> */}

      <div className='main' style={{flexGrow:"0"}}>
        <div className='box'>
        <div className='top_title' style={{ display:"flex", justifyContent:"space-between", width:"65%"}}>
            <img onClick={handleBack} className='arrow_back' src='/images/backArrow.svg' alt='arrow' />
            <h1 className='title' style={{textAlign:"center"}}>Payment Information</h1>
        </div>
          <div className='balance'>
                <p className='text_p1'>ayomide@getflick.app</p>
                <div className='amount-content'>
                    <h1 className='balance_amount'>₦620,000</h1>
                   <span><img src='/images/dollar.svg' alt='img' style={{width:"55px", height:"55px"}} /></span>
                </div>
            </div>
            {props.children}
        </div>
        {/* <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' />
            <div className='secured_content'>
                <p className='secured_p'>secured by</p>
                <img src='/images/flickLogo.svg' alt='logo'/>
            </div>
        </div> */}
      </div>
      <ToastContainer />
    </div>
  )
}

export default Layout
