import React, {useState} from 'react'
import { BeatLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../connectAccounts/connectAccount.scss'
// import SelectDropDown from '../checkout/landing/SelectDropDown';
import LandingLayout from '../checkout/layout/LandingLayout';


function FlickCheckout() {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line
    const [selectedBank, setSelectedBank] = useState(""); 
    const [isLoading,setIsLoading] = useState(false);
    // eslint-disable-next-line
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    // eslint-disable-next-line
    const handleBankSelection = (bankName) => {
      setSelectedBank(bankName);
      closeModal();
    };
      const handleLaunchDemo = async (e) => {
        e.preventDefault();
        setIsLoading(false);
        navigate('/checkout');
      }

  return (
  <>
        <div className='launch-demo'>
            <div className="connect-account-overlay">
            <div className="connect-account-main" style={{paddingTop:"30px"}}>
            <div className='flick-link-product'>
                <h1 className='flick-product-tour'>Flick <span style={{color:"#2EBDB6", textDecoration:"underline", textUnderlineOffset:"10px"}}>Link Checkout</span></h1>
                <p className='discover'>— Discover how our solutions can help you onboard more<br/> customers, request financial data and collect payments</p>
            </div>
            <div className="flick-link-box" style={{width:"100%", maxWidth:"497px"}}>
                <form className="flick-link-form" onSubmit={handleLaunchDemo}>
                    <LandingLayout landing >
                
                    {/* <div className='input_wrapper'>
                            <div className="input-container" onClick={openModal}>
                            {selectedBank ? (
                                <>
                                <h3 className="bank-name">{selectedBank.name}</h3>
                                </>
                            ) : (
                                <span >Choose option</span>
                            )}
                            <img
                                src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
                                alt="arrow"
                                style={{marginLeft:"auto"}}
                            />
                        </div>

                        {isModalOpen && (
                        <SelectDropDown
                            handleBankSelection={handleBankSelection}
                            closeModal={closeModal}
                            selectedBank={selectedBank}
                        />
                        )}
                   </div> */}

            </LandingLayout>

                <button id="continue-button" className="btn" style={{ background: '#2EBDB6', marginTop:"12px" }} disabled={isLoading}>
                    {isLoading ? (
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
                    ) : (
                    <>
                        Launch Demo
                        <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }} />
                    </>
                    )}
                </button>
                </form>
            </div>
            </div>
            <div className='foot-note'>You’re currently in the sandbox mode</div>
            <ToastContainer />
        </div>
        </div>
  </>
  )
}
export default FlickCheckout