import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

function Footer() {
  return (
    <div className='footer'>
            <div className='footer-main'>
                <div className='footer-flick'>
                    <img src={process.env.PUBLIC_URL + '/images/flickFooter.svg'} alt='footer' className='footer-flick-img'/>
                    <p className='footer_secure'>One secure platform to pay, get paid, and manage all your finances better!</p>
                    <div className='footer-download'>
                        <a href='https://onelink.to/zzh6zq'>
                            <img src={process.env.PUBLIC_URL + "/images/downLoadBtn.svg"} alt='googlePlay' className='download-link'/>
                        </a>
                        
                    </div>
                    <div className='ndpr'>
                       <div className='ndpr_img'>
                            <img src='/images/arm.svg' alt='arm'/>
                            <img src='/images/pci.svg' alt='arm'/>
                       </div>
                      
                    </div>
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Company</h1>
                    <div className='footer-menu-link'>
                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "https://getflick.app/about"}>About us</Link></li>
                            <li> <Link to={process.env.PUBLIC_URL + "https://getflick.app/careers"}>Careers</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "https://getflick.app/pricing"}>Pricing</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "https://getflick.app/faqs"}>FAQs</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "https://getflick.app/privacy-policy"}>Privacy policy</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "https://getflick.app/terms-conditions"}>Terms & conditions</Link></li>
                        </ul> 
                    </div>   
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Developers</h1>
                    <div className='footer-menu-link'>
                        <ul className="list-unstyled">
                            <li><a href="https://docs.getflick.co/docs" target="_blank" rel="noopener noreferrer" >Overview</a></li>
                            <li><a href="https://docs.getflick.co/reference" target="_blank" rel="noopener noreferrer" >API Documentation</a></li>
                            <li><Link to={process.env.PUBLIC_URL + "/404"}>Support</Link></li>
                        </ul> 
                    </div>   
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Address</h1>
                    <div className='address'>
                        <img src={process.env.PUBLIC_URL + "/images/NgFlag.svg"}  alt="flag"/>
                        <p>6th Floor, Landmark Towers, <br/> 5B Water Corporation Road,<br/> VI, Lagos</p>
                    </div>
                    <div className='address'>
                        <img src={process.env.PUBLIC_URL + "/images/UnFlag.svg"}  alt="flag"/>
                        <p>2261 Market Street #4664 <br/> San Francisco, CA 9114</p>
                    </div>
                </div>

                
            </div>

            {/*  */}

            <div className='footer-line'></div>

            <div className='footer-aside'>
                <h6 className='copywrite'>© 2023 Flick. All rights reserved || A product of QRaba Inc.</h6>
                <div className='footer-action'>
                    <div className='foot-action-btn'>
                        <Link className='unsubscribe' to="">Unsubscribe</Link>
                        <Link className='delete'  to="">Delete profile</Link> 
                    </div>
                    <ul className='list-unstyled footer-social-link'>
                        <li><a href="https://www.facebook.com/getflick.africa/"><img src={process.env.PUBLIC_URL + "images/facebook.svg"} alt="fb"/></a></li>
                        <li><a href="https://www.linkedin.com/company/getflick/"><img src={process.env.PUBLIC_URL + "images/linkedin.svg"} alt="fb"/></a></li>
                        <li><a href="https://www.instagram.com/getflick.africa/"><img src={process.env.PUBLIC_URL + "images/twitter.svg"} alt="fb"/></a></li>
                        <li><a href="https://twitter.com/getflick_africa/"><img src={process.env.PUBLIC_URL + "images/instagram.svg"} alt="instagram"/></a></li>
                    </ul>            
                </div>
            </div>
        
    </div>
  )
}

export default Footer