import React, {useState, useEffect} from 'react';
import Header from '../header/Header';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'


function SuccessPage() {
  const selectedProduct = localStorage.getItem('selectedProduct');
  const [displayedResponse, setDisplayedResponse] = useState('json');
  const handleResponse = (type) => {
    setDisplayedResponse(type);
  };

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);

  const transactionsData = [
    {
      "data": [
        {
          "Nuban": "387346015",
          "datedStamp": "2023-09-21T17:03:15.611Z",
          "Amount": "225000",
          "dated": "2023-09-21T00:00:00.000Z",
          "TransactionType": "credit",
          "Narration": "N-10140722181/Collection Credit for 20",
          "TransType": "credit",
          "Balance": "30933952",
          "BeneficiaryName": "null"
        },
        {
          "Nuban": "6767346095",
          "datedStamp": "2023-09-21T17:03:15.612Z",
          "Amount": "150000",
          "dated": "2023-09-21T00:00:00.000Z",
          "TransactionType": "credit",
          "Narration": "N-10140716665/Collection Credit for 20",
          "TransType": "credit",
          "Balance": "30708952",
          "BeneficiaryName": "null"
        },
        {
          "Nuban": "6767346875",
          "datedStamp": "2023-09-21T17:03:15.613Z",
          "Amount": "900000",
          "dated": "2023-09-21T00:00:00.000Z",
          "TransactionType": "credit",
          "Narration": "N-10140720013/Collection Credit for 20",
          "TransType": "credit",
          "Balance": "30558952",
          "BeneficiaryName": "null"
        }
      ]
    }
  ]
  const balanceData = [
    {
      "status": 200,
      "bank": "AccessBank",
      "accountNumber": "0011223344",
      "accountType": "premium saving",
      "bankingType": "Personal Banking",
      "currency": "NGN",
      "balance": "59050"
    }
  ]
  const statementData = [
    {
      "data": [
        {
          "Nuban": "001122520",
          "datedStamp": "2023-11-27T10:29:09.315Z",
          "bankName": "Zenith Bank",
          "currency": "NGN",
          "bvn": "2211221122",
          "Amount": "10000",
          "dated": "2023-10-09T19:53:00.000Z",
          "TransactionType": "debit",
          "Narration": "MC Loc Web-707390016959--",
          "TransType": "debit",
          "Balance": "12558",
          "BeneficiaryName": "null"
        },
        {
          "Nuban": "001122520",
          "datedStamp": "2023-11-27T10:29:09.316Z",
          "bankName": "Zenith Bank",
          "currency": "NGN",
          "bvn": "2211221122",
          "Amount": "20000",
          "dated": "2023-10-09T15:32:00.000Z",
          "TransactionType": "credit",
          "Narration": "Buying",
          "TransType": "credit",
          "Balance": "22558",
          "BeneficiaryName": "null"
        }
      ]
    }
  ]
  const identityData = [
    {
      "data": {
        "email": "customer@getDflick.com",
        "phone": "07000319811",
        "bvn": "0033889933",
        "dob": "01-10-2023",
        "gender": "male",
        "maritalStatus": "married",
        "address": "4b lekki lagos"
      }
    }
  ]

  const jsonData = [
    {
      "accounts": {
        "accounts": [
          {
            "account_id": "MpdjQdZwgBCLkj7KpxMvFJadlaMaJAfL5qg3k",
            "balances": {
            "available": 100,
            "current": 110,
            "iso_currency_code": "USD",
            "limit": null
            }
          }
        ]
      }
    }
  ]
  let srcData;
  if (selectedProduct === 'transactions') {
    srcData = transactionsData;
  } else if (selectedProduct === 'balance') {
    srcData = balanceData;
  } else if (selectedProduct === 'statement') {
    srcData = statementData;
  } else if (selectedProduct === 'identity') {
    srcData = identityData;
  }

    // eslint-disable-next-line 
    const accounts = jsonData[0].accounts.accounts;

    const getDataForSelectedProduct = () => {
      switch (selectedProduct) {
        case 'transactions':
          return transactionsData;
        case 'balance':
          return balanceData;
        case 'statement':
          return statementData;
        case 'identity':
          return identityData;
        default:
          return [];
      }
    };
  
    // const renderTableRows = (data) => {
    //   return data.map((item, index) => (
    //     <React.Fragment key={index}>
    //       {/* Render your table rows based on the data structure */}
    //     </React.Fragment>
    //   ));
    // };

    const renderTableRows = (data) => {
      // return data.map((item, index) => (
      //   <tr key={index}>
      //     {Object.keys(item).map((key, innerIndex) => (
      //       <React.Fragment key={innerIndex}>
      //         <th>{key}</th>
      //         <td>{item[key]}</td>
      //       </React.Fragment>
      //     ))}
      //   </tr>
      // ));
    };    

  return (
    <div className='landing_page'>
      <Header />
      <div className='success-page'>
        <div className='success'>
          <h1 className='flick-product-tour'>Success!</h1>
          <p className='discover' style={{marginBottom:"16px"}}>
            — You have completed Flick Link. Your <br/>
            credentials were verified and used to retrieve the <br/>
            account data shown.
          </p>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/api-documentation.svg'} alt="logo" />
          </div>
        </div>
        <div className='response-data'>
        <div style={{marginBottom:"16px"}}>
            {selectedProduct === 'statement' && (
              <img src={process.env.PUBLIC_URL + '/images/statement.svg'} alt="statement" />
            )}
            {selectedProduct === 'transactions' && (
              <img src={process.env.PUBLIC_URL + '/images/transaction.svg'} alt="transaction" />
            )}
          </div>
          <div className='response'> 
            <div className='response-url'>
              <p className='url'>POST / accounts / {selectedProduct}</p>
              <div className='response-type'>
                <p className={`type ${displayedResponse === 'tables' ? 'active' : ''}`} onClick={() => handleResponse('tables')}>RENDERED</p>
                <p className={`type ${displayedResponse === 'json' ? 'active' : ''}`} onClick={() => handleResponse('json')}>JSON</p>
              </div>
            </div>
            {displayedResponse === 'tables' ? (
              <div className='rendered'>
               <table className="column-layout">
              <tbody>
                {/* {accounts.map((account, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th>{Object.keys(account.account_id)[0]}</th>
                      <td>{account.balances.available}</td>
                    </tr>
                    <tr>
                      <th>{Object.keys(account.balances)[0]}</th>
                      <td>{account.balances.available}</td>
                    </tr>
                    <tr>
                      <th>{Object.keys(account.balances)[1]}</th>
                      <td>{account.balances.current}</td>
                    </tr>
                    <tr>
                      <th>{Object.keys(account.balances)[2]}</th>
                      <td>{account.balances.iso_currency_code}</td>
                    </tr>
                    <tr>
                      <th>{Object.keys(account.balances)[3]}</th>
                      <td>{account.balances.limit}</td>
                    </tr>
                  </React.Fragment>
                ))} */}
                {renderTableRows(getDataForSelectedProduct())}
              </tbody>
            </table>
              </div>
            ) : (
              <div className='json'>
                <p className='sandbox'>*** Sandbox data below ***</p>
                {selectedProduct && (
                  <JsonView src={srcData} theme='canary' />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
