import React from 'react'
import './layout.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function LandingLayout(props) {
  // eslint-disable-next-line
  const navigate = useNavigate()
  // const handleBack = ()=>{
  //   navigate(props.pathback);
  // }

  // const handleHome = ()=>{
  //   navigate('/')
  // }

  return (

      <div className='main'>
        <div className='landing-box'>
          <div className='top_title' style={{display:"flex", flexDirection:"column"}}>
            <h1 className='title'>Payment Information</h1>
            <p className='option_desc'>Please select a preferred payment option</p>
          </div>
            <div className='balance'>
                <p className='text_p1'>ayomide@getflick.app</p>
                <div className='amount-content'>
                    <h1 className='balance_amount'>₦620,000</h1>
                   <span><img src='/images/dollar.svg' alt='img' style={{width:"55px", height:"55px"}} /></span>
                </div>
            </div>
            {props.children}
        </div>
      <ToastContainer />
      </div>
  )
}

export default LandingLayout
