import React, { useEffect, useState } from 'react';
import BankTransfer from './BankTransfer';
import loadingTransfer from './loadingTransfer.json';
import loadingTransferEnd from './loadingTransferEnd.json';
import Lottie from 'lottie-react'
import { useNavigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';

function ConfirmBankTransfer({transferData}) {

  const navigate = useNavigate();
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [isTimeLimitExceeded, setIsTimeLimitExceeded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isSuccess,setIsSuccess] = useState(false)

  const toggleAccountDetails = () => {
    setShowAccountDetails(!showAccountDetails);
  };

  useEffect(() => {
    let timeoutId;

    const startTimer = () => {
      setTimeLeft(180);
      timeoutId = setTimeout(() => {
        setIsTimeLimitExceeded(true);
      }, 180000);
    };

    const startPolling = () => {
      startTimer(); 

      const intervalId = setInterval(() => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setIsSuccess(true);
        navigate("/checkout-success",{state:{transferData}});
      }, 5000); 

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    };

    startPolling();
  
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
      <div className="confirm-transfer">
        {
          !isSuccess ?
            <h1 className="confirm">We’re waiting to confirm your transfer. This can take a few minutes</h1> :
            <h1 className='confirm'>Transaction Successful</h1>
        }
        {
          !isTimeLimitExceeded ?
            <div className='lottie-size'>
              <Lottie animationData={loadingTransfer} /> 
            </div> :
            <div className='lottie-size'>
              <Lottie loop={false} animationData={loadingTransferEnd} /> 
            </div>
        }
        {
          isTimeLimitExceeded ? (
            <button className="sec-btn">
              Get help for this transaction
            </button>
          ) : (
            <button className="sec-btn">
              Please wait for {formatTime(timeLeft)} minutes
            </button>
          )
        }
        <p className="show-account" onClick={toggleAccountDetails}>
          {showAccountDetails ? 'Hide account details' : 'Show account details'}
        </p>
        {showAccountDetails && <BankTransfer hideSendMoneyButton={true} transferData ={transferData} />}
        <ToastContainer />
      </div>
  );
}

export default ConfirmBankTransfer;
