import React, { useState } from "react";
import "./connectAccount.scss";
import { useNavigate} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";

function ConnectAccounts() {
  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const [isLoading, setIsLoading] = useState(false);


  const handleClick = async () => {
    setIsLoading(true)
    navigate("/pages/account-connected")
  };
  const handleBack = () => {
    navigate("/pages/enter-credentials");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + "/images/arrowBack.svg"}
              alt="back"
              style={{ width: "16px", height: "16px" }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              style={{ marginBottom: "27px" }}
              onClick={handleBack}
            />
          </div>
          <div style={{ padding: "0px 21px 21px 21px" }}>
            <div className="connect-accounts-image">
              <div className="connect-accounts">
                <p className="connect-one">Connect one or more accounts</p>
                <p className="account-name">Account name: <span style={{ color: "#151F32" }}>AJIBADE AYOMIDE DAVID</span></p>
              </div>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{ width: "34px", height: "35.4px" }}
              />
            </div>
            <div className="account-type-container">
                <div className="account-type" >
                  <div className="account-type-text">
                    <p className="type-of-account">Savings Account(NGN)</p>
                    <p className="account-number">0224048917</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value=""
                    disabled
                    className="custom-radio"
                  />
                </div>
            </div>

            <div className="account-type-container">
                <div className="account-type" >
                  <div className="account-type-text">
                    <p className="type-of-account">Current Account(NGN)</p>
                    <p className="account-number">0224048917</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value=""
                    disabled
                    className="custom-radio"
                  />
                </div>
            </div>

            <div className="account-type-container">
                <div className="account-type" >
                  <div className="account-type-text">
                    <p className="type-of-account">Mastercard/Visa Debit Card(NGN)</p>
                    <p className="account-number">0224048917</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value=""
                    disabled
                    className="custom-radio"
                  />
                </div>
            </div>
            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px", width:"14px", height:"14px" }}/>
                </>
              )}
            </button>
            <div className="secured-flick">
              <img
                src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                alt="flick"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className='foot-note'>You’re currently in the sandbox mode</div>
    </div>
  );
}

export default ConnectAccounts;
